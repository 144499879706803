import { useEffect, useState } from "react";



import { Box } from "@chakra-ui/react";



import { debounce } from "~/utils/commons";


const AppBarWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        position: "sticky",
        top: "env(safe-area-inset-top)",
        zIndex: 99,
        bg: "#FFF",
      }}
      px={[1, 1, 0]}
      borderBottom={"1px solid"}
      borderColor={"brand.light-grey"}
      display={{ base: "flex", lg: "none" }}
    >
      {children}
    </Box>
  );
};

export default AppBarWrapper;